import { useLocation, useNavigate } from 'react-router-dom';
import ThankYouImage from '../../assets/thank-you.jpeg'
import { useEffect } from 'react';

const ThankYou = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if(!location?.state?.success) {
      return navigate("/")
    }

  },[location?.state?.success, navigate])

  if(!location?.state?.success) return null;

  return (
    <div style={{backgroundColor: '#2a95af'}} className='flex items-center justify-center'>
      <img src={ThankYouImage} alt="thank-you" />
    </div>
  );
};

export default ThankYou;
