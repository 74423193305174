import ImageSection, {
  BackgroundImageSection,
} from "../../components/image-section/image-section";
import ShopImg from "../../assets/shop-img.jpg";
import StarIcon from "../../assets/star.svg";
import ShareIcon from "../../assets/Share.svg";
import SaveIcon from "../../assets/Save.svg";
import MoreIcon from "../../assets/More.png";
import LocationIcon from "../../assets/Location.svg";
import BadgeIcon from "../../assets/badge.svg";
import SmallArrowRightIcon from "../../assets/small-arrow-right.svg";
import SmallArrowLeftIcon from "../../assets/small-arrow-left.svg";
import HeartIcon from "../../assets/heart.svg";
import StarFullIcon from "../../assets/star-full.svg";
import StarHalfIcon from "../../assets/star-half.svg";
import Button from "../../components/button/button";
import Body from "../../components/body/body";
import CloseDarkIcon from "../../assets/close-dark.svg";
import { Heading, SubHeading } from "../../components/headings/headings";
import Input from "../../components/input/input";
import SelectInputOnly from "../../components/select-input-only/select-input-only";
import VerifiedBadge from "../../components/verified-badge/verified-badge";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { useParams } from "react-router-dom";
import { useEffect, useState, useCallback, useRef } from "react";
import useFetch from "../../hooks/use-fetch.hook";
import { useDispatch, useSelector } from "react-redux";
import { updateLocation } from "../../lib/redux/slices/location.slice";
import { useLocation } from "../../hooks/use-location";
import { getDistanceBetweenTwoLatLonInKm } from "../../utils/calulate-distance";
import { toast } from "react-toastify";
import CenterComponent, {
  Loader,
} from "../../components/center-component/center-component";
import { getErrorOtpSection } from "../../utils/validation";
import AxiosInstance from "../../lib/axios/axios-instance";
import SimilarShops from "../similar-shops/similar-shops";
import SimilarProducts from "../similar-products/similar-products";

const axiosInstance = AxiosInstance.getInstance();

const SelectInputWithWrapper = () => {
  return (
    <div className="text-[#8D8A95]">
      <SelectInputOnly />
    </div>
  );
};

const UserTag = () => {
  return (
    <div className="w-80">
      <div className="w-[inherit] h-52 rounded-tl-2xl rounded-tr-2xl overflow-hidden">
        <ImageSection imgUrl={ShopImg} />
      </div>
      <div className="h-auto flex flex-col justify-center gap-2.5 border border-t-0 rounded-bl-2xl rounded-br-2xl py-5 px-4">
        <div>
          <h3 className="font-semibold text-sm leading-6 text-[#110C22]">
            Sofa Cabby- Wooden Sofa Couch
          </h3>
        </div>
        <SelectInputWithWrapper />
        <SelectInputWithWrapper />
      </div>
    </div>
  );
};

const ManageUserTag = () => {
  return (
    <Body>
      <div className="flex items-center justify-between gap-3">
        <div>
          <Heading>Manage User Tags</Heading>
        </div>
        <div className="w-96">
          <SelectInputWithWrapper />
        </div>
      </div>
      <div className="mt-7 flex items-center gap-6">
        <UserTag />
        <UserTag />
      </div>
    </Body>
  );
};

const Card = () => {
  return (
    <div className={`w-[240px] h-[200px] relative`}>
      <div className={`w-[240px] h-[200px] rounded-2xl overflow-hidden`}>
          <BackgroundImageSection imgUrl={ShopImg} />
      </div>
      <div className="font-semibold text-sm leading-6 text-[#FFFFFF] absolute bottom-4 left-4">
        Sports
      </div>
    </div>
  );
};

const Category = ({ text }) => {
  return (
    <div className="text-xs font-normal text-[#4F4B5C] text-center border py-1 px-2 rounded-lg">
      {text}
    </div>
  );
};

const ProductCard = () => {
  return (
    <div className="w-80">
      <div className="w-[inherit] h-52 w-full h-36 rounded-tl-2xl rounded-tr-2xl overflow-hidden">
        <ImageSection imgUrl={ShopImg} btnImgUrl={HeartIcon} />
      </div>
      <div className="h-auto flex flex-col justify-center gap-2 border border-t-0 rounded-bl-2xl rounded-br-2xl py-3 px-4">
        <div>
          <h3 className="font-semibold text-sm leading-6 text-[#110C22]">
            Sofa Cabby- Wooden Sofa Couch
          </h3>
        </div>

        <div className="flex items-center gap-2">
          <div className="flex items-center">
            <div className="h-6 w-6">
              <img src={StarFullIcon} alt="star-full" />
            </div>
            <div className="h-6 w-6">
              <img src={StarFullIcon} alt="star-full" />
            </div>
            <div className="h-6 w-6">
              <img src={StarFullIcon} alt="star-full" />
            </div>
            <div className="h-6 w-6">
              <img src={StarFullIcon} alt="star-full" />
            </div>
            <div className="h-6 w-6">
              <img src={StarHalfIcon} alt="star-half" />
            </div>
          </div>
          <div>
            <span className="font-normal text-sm leading-6 text-[#110C22]">
              (4.5)
            </span>
          </div>
        </div>

        <div className="flex items-center gap-3">
          <div className="flex items-center gap-1">
            <span className="font-light text-2xl text-[#110C22]">₹</span>
            <span className="text-[#110C22] font-medium text-lg">720</span>
          </div>
          <div className="flex items-center gap-1">
            <span className="font-light text-2xl text-[#8D8A95]">₹</span>
            <span className="text-[#8D8A95] font-medium text-lg line-through">
              820
            </span>
          </div>
        </div>

        <div className="flex items-center gap-4">
          <div className="w-1/2">
            <Button>View Product</Button>
          </div>
          <div className="w-1/2">
            <Button textColor="#110C22" bgColor="transparent">
              Wishlist
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const ReSendOtpButton = ({ sendOtp, isValidating, timer, setTimer }) => {
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      }
    }, 1000);

    if (timer === 0) {
      clearInterval(myInterval);
    }

    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <Button
      bgColor="transparent"
      textColor="#110C22"
      onClick={sendOtp}
      disabled={isValidating || timer > 0}
    >
      {isValidating
        ? "Sending..."
        : `Re-send ${timer > 0 ? `in` : "OTP"} ${
            timer > 0 ? `(${timer})` : ""
          }`}
    </Button>
  );
};

const KYCVerificationModal = ({ onClose, onReload, data }) => {
  const [otpSent, setOtpSent] = useState(false);
  const [payload, setPayload] = useState({
    address: data.address,
    shopOwnerContactNumber: "",
  });
  const [otp, setOtp] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [shopId, setShopId] = useState(null);
  const [timer, setTimer] = useState(60);

  const sendOtp = async () => {
    setIsValidating(true);
    const error = getErrorOtpSection(payload);

    if (error) {
      setIsValidating(false);
      return toast.error(error);
    }

    try {
      const { data } = await axiosInstance.post(
        "/claim-shops/verification",
        payload
      );
      setShopId(data.id);
      setIsValidating(false);
      setOtpSent(true);
      toast.success("OTP sent successfully.");
      setTimer(60);
    } catch (err) {
      console.error(err);
      toast.error(err?.response?.data?.message?.toString());
      setIsValidating(false);
    }
  };

  const verifyOtp = async () => {
    setIsVerifying(true);
    if (!otp || !otp.trim()) {
      setIsVerifying(false);
      return toast.error("Please provide a valid OTP.");
    }

    try {
      await axiosInstance.patch(`/claim-shops/verify-otp/${shopId}`, {
        otp: otp,
        mobileNumber: payload.shopOwnerContactNumber,
      });

      await onReload();
      onClose();
      setIsVerifying(false);
    } catch (err) {
      console.error(err);
      toast.error(err?.response?.data?.message?.toString());
      setIsVerifying(false);
    }
  };

  const updateKeyValue = (key, value) => {
    setPayload({ ...payload, [key]: value });
  };

  const onChange = (event) => {
    updateKeyValue(event.target.name, event.target.value);
  };

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex mt-36 md:mt-0 md:min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="flex justify-between items-center mt-3 sm:text-left">
                <div>
                  <h3
                    className="text-sm font-semibold leading-6"
                    id="modal-title"
                  >
                    KYC Verification
                  </h3>
                  <SubHeading>Enter details to validate</SubHeading>
                </div>
                <div>
                  <button className="p-1 text-black" onClick={onClose}>
                    <img src={CloseDarkIcon} alt="close-icon" />
                  </button>
                </div>
              </div>

              <div className="mt-4 flex flex-col gap-4">
                <Input
                  name="shopOwnerContactNumber"
                  label="Enter Mobile number"
                  type="text"
                  disabled={otpSent}
                  onChange={onChange}
                />
                <Input
                  name="address"
                  label="Address"
                  type="text"
                  value={payload.address}
                  disabled={true}
                />
                {otpSent && (
                  <Input
                    label="OTP"
                    name="otp"
                    type="text"
                    onChange={(e) => setOtp(e.target.value)}
                    minLength={6}
                    maxLength={6}
                    disabled={isVerifying || isValidating}
                  />
                )}
              </div>
            </div>

            {otpSent ? (
              <div className="p-6 flex gap-2 items-center justify-end">
                <ReSendOtpButton
                  sendOtp={sendOtp}
                  isValidating={isVerifying || isValidating}
                  timer={timer}
                  setTimer={setTimer}
                />
                <Button
                  onClick={verifyOtp}
                  disabled={isVerifying || isValidating}
                >
                  {isVerifying ? "Verifying..." : "Verify OTP"}
                </Button>
              </div>
            ) : (
              <div className="p-6 flex gap-2 items-center justify-end">
                <Button
                  bgColor="transparent"
                  textColor="#110C22"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button onClick={sendOtp} disabled={isValidating}>
                  {isValidating ? "Sending..." : "Send OTP"}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const ShowSlides = ({ dataList, Card }) => {
  return dataList.map((details, index) => (
      <Card key={details}/>
  ));
};

const CategoriesCarousel = ({
  title,
  dataList,
  children,
  Card,
  naturalSlideHeight,
  naturalSlideWidth,
  visibleSlides,
  cardWidth = 316
}) => {

  const divRef = useRef(null)
  const speed = 30
  const [hasLeftCards, setHasLeftCards] = useState(false);
  const [hasRightCards, setHasRightCards] = useState(true);
  

  const sideScroll = (element,direction,speed,distance,step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
        if(direction === 'left'){
            element.current.scrollLeft -= step;
        } else {
            element.current.scrollLeft += step;
        }

        scrollAmount += step;

        if(scrollAmount >= distance){
            window.clearInterval(slideTimer);
        }
    }, speed);
}


  const onLeft = () => {
    if(divRef.current.scrollLeft === 0) {
      return
    }
    sideScroll(divRef, 'left', speed, cardWidth, 20);
  }

  const onRight = () => {
    sideScroll(divRef, 'right', speed, cardWidth, 20);
  }

  const handleScroll = e => {
    const totalWidth = Number(divRef.current.scrollWidth) - Number(divRef.current.offsetWidth)

    if(divRef.current.scrollLeft > 0) {
      setHasLeftCards(true)
    }

    if(divRef.current.scrollLeft >= totalWidth) {
      setHasRightCards(false)
    }

    if(divRef.current.scrollLeft < totalWidth) {
      setHasRightCards(true)
    }

    if(divRef.current.scrollLeft === 0) {
      setHasLeftCards(false)
    } 
  }


  return (
    <div className="flex flex-col gap-3 mt-10">
        <div className="flex items-center justify-between">
          <div className="font-semibold text-lg leading-6 text-[#110C22]">
            {title}
          </div>
          <div className="flex items-center gap-1">
            <button
              className="border rounded-lg px-3 py-2.5 disabled:opacity-25"
              onClick={onLeft}
              disabled={!hasLeftCards}
            >
              <img
                className="rotate-180"
                src={SmallArrowLeftIcon}
                alt="arrow-left"
              />
            </button>
            <button disabled={!hasRightCards} className="border rounded-lg px-3 py-2.5 disabled:opacity-25" onClick={onRight}>
              <img src={SmallArrowRightIcon} alt="arrow-right" />
            </button>
          </div>
        </div>

        {children}

        <div ref={divRef} className="mt-1 flex items-center gap-6 overflow-y-scroll hide-scrollbar pointer-events-none" onScroll={handleScroll} onLoad={handleScroll}>
           <ShowSlides dataList={dataList} Card={Card} />

        </div>
</div>
  )


  return (
    <div className="w-full mt-12 h-56">
      <CarouselProvider
        naturalSlideWidth={naturalSlideWidth}
        naturalSlideHeight={naturalSlideHeight}
        totalSlides={dataList.length}
        visibleSlides={visibleSlides}
        step={1}
      >
        <div className="flex items-center justify-between">
          <div className="font-semibold text-lg leading-6 text-[#110C22]">
            {title}
          </div>
          <div className="flex items-center gap-1">
            <ButtonBack className="border rounded-lg px-3 py-2.5 disabled:opacity-25">
              <img
                className="rotate-180"
                src={SmallArrowLeftIcon}
                alt="arrow-left"
              />
            </ButtonBack>
            <ButtonNext className="border rounded-lg px-3 py-2.5 disabled:opacity-25">
              <img src={SmallArrowRightIcon} alt="arrow-right" />
            </ButtonNext>
          </div>
        </div>

        {children}

        <Slider>
          <div className="mt-6">
            <div className="flex items-center gap-6">
              <ShowSlides dataList={dataList} Card={Card} />
            </div>
          </div>
        </Slider>
      </CarouselProvider>
    </div>
  );
};

const ShowCategories = ({ categories }) => {
  return categories.map((category) => (
    <Category key={category.id} text={category.label} />
  ));
};


const ClaimShopDetails = () => {
  let { shopId } = useParams();
  const [showModal, setShowModal] = useState(false);

  const { isLoading, data, error, reload } = useFetch({
    url: `/claim-shops/${shopId}`,
  });
  const { isLocating, location, error: locationError } = useLocation();
  const dispatch = useDispatch();

  const getLocationDetal = useCallback(() => {
    if (!location) {
      return;
    }

    try {
      dispatch(
        updateLocation({
          latitude: location.latitude,
          longitude: location.longitude,
        })
      );
    } catch (err) {
      console.error(err);
    }
  }, [dispatch, location]);

  useEffect(() => {
    getLocationDetal(location);
  }, [location, getLocationDetal]);

  const locationDetails = useSelector((state) => state.location);

  const displayModal = () => setShowModal(true);
  const hideModal = () => setShowModal(false);

  if (isLoading) {
    return (
      <CenterComponent>
        <Loader />
      </CenterComponent>
    );
  }

  if (!data) {
    return <CenterComponent>No Shop data available.</CenterComponent>;
  }

  if (error) {
    return <CenterComponent>{error}</CenterComponent>;
  }

  const {
    id,
    name,
    storeFrontPicture,
    address,
    intro,
    categories,
    isClaimed,
    lat,
    long,
    subCategories
  } = data;

  const currLatitude = locationDetails?.latitude;
  const currLongitude = locationDetails?.longitude;

  const onShare = async () => {
    try {
      if (!navigator?.share) {
        throw new Error("Share API not available in the device.");
      }

      const shareData = {
        title: "DoDong",
        text: name,
        url: window.location.href,
      };
      await navigator.share(shareData);
      toast.success(`Shared successfully.`);
    } catch (err) {
      toast.error(err.message);
    }
  };

  if (showModal) {
    return (
      <KYCVerificationModal data={data} onClose={hideModal} onReload={reload} />
    );
  }

  return (
    <Body>
      <div className="flex gap-5 md:flex-row flex-col">
        <div className="md:h-80 md:w-[566px] w-full h-96 rounded-2xl overflow-hidden">
          <BackgroundImageSection imgUrl={storeFrontPicture} />
        </div>
        <div className="w-full relative flex flex-col justify-between md:gap-0 gap-5">
          <div>
            {lat && long && currLatitude && currLongitude && (
              <div className="flex items-center gap-1">
                <div>
                  <img src={StarIcon} alt="star-icon" />
                </div>
                <div className="font-medium text-xs leading-4">
                  {getDistanceBetweenTwoLatLonInKm(
                    lat,
                    long,
                    currLatitude,
                    currLongitude
                  )}{" "}
                  km
                </div>
              </div>
            )}
            <div className="mt-4">
              <h3 className="font-normal text-3xl leading-10">{name}</h3>
            </div>
            <div className="flex items-center justify-end relative mb-4">
              <div className="flex items-center gap-4 absolute">
                <div>
                  <button title="share" aria-label="Share" onClick={onShare}>
                    <img src={ShareIcon} alt="share-icon" />
                  </button>
                </div>
                <div>
                  <button aria-label="Save">
                    <img src={SaveIcon} alt="save-icon" />
                  </button>
                </div>
                <div>
                  <button aria-label="More">
                    <img src={MoreIcon} alt="more-icon" />
                  </button>
                </div>
              </div>
            </div>

            <div className="mt-3 flex gap-2">
              <div>
                <img src={LocationIcon} alt="location-icon" />
              </div>
              <div className="flex flex-col gap-1">
                {lat && long && currLatitude && currLongitude && (
                  <div className="font-normal text-xs">
                    {getDistanceBetweenTwoLatLonInKm(
                      lat,
                      long,
                      currLatitude,
                      currLongitude
                    )}{" "}
                    km
                  </div>
                )}
                <p className="font-light text-sm leading-5 text-[#8D8A95]">
                  {address}
                </p>
              </div>
            </div>

            <div className="mt-5">
              <p className="font-light text-sm leading-5">{intro}</p>
            </div>
          </div>

          <div className="flex items-center gap-4">
            <div className="font-normal text-xs text-[#8D8A95]">Categories</div>
            <div className="flex items-center w-auto gap-1">
              <ShowCategories categories={categories} />
            </div>
          </div>

          {isClaimed ? (
            <div>
              <div className="md:w-24">
                <VerifiedBadge />
              </div>
            </div>
          ) : (
            <div>
              <div className="md:w-48">
                <Button disabled={isClaimed} onClick={displayModal}>
                  <div className="flex gap-1 items-center justify-center">
                    <div>
                      <img src={BadgeIcon} alt="badge" />
                    </div>
                    <div>Claim My Shop</div>
                  </div>
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>

      <CategoriesCarousel
        title="Categories"
        dataList={[0,1,2,3,4,5,6,7]}
        Card={Card}
        naturalSlideWidth={50}
        naturalSlideHeight={50}
        visibleSlides={5}
        cardWidth={245}
      />
      <div className="flex flex-col gap-3 mt-2">
        <CategoriesCarousel
          title="Products"
          dataList={[0,1,2,3,4,5,6,7]}
          Card={ProductCard}
          naturalSlideWidth={50}
          naturalSlideHeight={55}
          visibleSlides={1}
          cardWidth={316}
        >
          <div className="flex items-center gap-2 mt-3 overflow-y-scroll">
            <div className="py-1 px-4 border text-center rounded-lg border-[#FF9800] bg-[#FF9800]">
              <span className="font-normal text-xs text-white">All</span>
            </div>

            {['Electronics', 'Travel', 'Pet','Fashion','Essentials'].map(text => (<div key={text} className="py-1 px-4 border text-center rounded-lg border-[#ECECED]">
              <span className="font-normal  text-xs text-[#4F4B5C]">
                {text}
              </span>
            </div>))}
          </div>
        </CategoriesCarousel>
      </div>
    </Body>
  );
};

export default ClaimShopDetails;
