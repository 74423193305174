import { memo } from "react";

export const CardHeadingComponent = ({ heading }) => {
  return (
    <div className="sm:px-6 sm:py-3 px-2 py-2">
      <p className="font-semibold text-sm leading-6 text-[#110C22]">
        {heading}
      </p>
    </div>
  );
};

export const CardHeading = memo(CardHeadingComponent)