import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo.svg";
import Avatar from "../../assets/avatar.svg";
import MenuIcon from "../../assets/menu.svg";
import MoreIcon from "../../assets/More.png";
import UsersIcon from "../../assets/users.png";
import GalleryAddIcon from "../../assets/gallery-add.png";
import GlobalIcon from "../../assets/global.png";
import { useNavigate } from "react-router-dom";

const PrevNavItemList = [
  {
    name: "My Shop",
    iconImg: UsersIcon,
    id: 1,
  },
  {
    name: "Claim Shop",
    iconImg: UsersIcon,
    id: 2,
  },
  {
    name: "Manage Tags",
    iconImg: UsersIcon,
    id: 3,
  },
  {
    name: "Add Offers/Disc.",
    iconImg: GalleryAddIcon,
    id: 4,
  },
  {
    name: "Manage Products",
    iconImg: GlobalIcon,
    id: 5,
  },
  {
    name: "Manage Shop",
    iconImg: MoreIcon,
    id: 5,
  },
  {
    name: "Settings",
    iconImg: MoreIcon,
    id: 6,
  },
];

const NavItemList = [
  {
    name: "Privacy Policy",
    iconImg: UsersIcon,
    id: 1,
    path:'/privacy-policy'
  },
  {
    name: "Shipping Policy",
    iconImg: GlobalIcon,
    id: 2,
    path:'/shipping-policy'
  },
  {
    name: "Terms and Conditions",
    iconImg: UsersIcon,
    id: 3,
    path:'/terms-and-conditions'
  },
  {
    name: "Refund and Cancellation Policy",
    iconImg: MoreIcon,
    id: 4,
    path:'/refund-and-cancellation-policy'
  },
  {
    name: "Contact Us",
    iconImg: UsersIcon,
    id: 5,
    path:'/contact-us'
  },
  {
    name: "Our Services",
    iconImg: MoreIcon,
    id: 5,
    path:'/services'
  }
];

export const DisplayNavItem = ({toggleMenu, navItemList}) => {
  return navItemList.map((navItem) => (
    <NavMenuItem
      key={navItem.id}
      text={navItem.name}
      iconImg={navItem.iconImg}
      path={navItem?.path}
      link={navItem?.link}
      toggleMenu={toggleMenu}
    />
  ));
};

export const NavMenuItem = ({ text, iconImg, path, link,toggleMenu }) => {
  const navigate = useNavigate();

  const onClick = () => {
    if(!path && !link) return
    
    if(link) {
      return window.open(link, "_blank", "noreferrer");
    }
    
    toggleMenu()
    navigate(path)
  }

  return (
    <button className="flex items-center gap-3 px-3 w-auto" onClick={onClick} >
      <div>
        <img src={iconImg} alt="icon" />
      </div>
      <div className="font-medium text-sm">{text}</div>
    </button>
  );
};

const NavBar = () => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => setShowMenu(!showMenu);

  return (
    <header>
      <div className="flex justify-between p-4 border-b relative">
        <Link to="/">
          <img src={Logo} alt="logo" />
        </Link>
        <div className="flex justify-between w-24">
          <div className="h-10 w-10 rounded-xl">
            <img src={Avatar} alt="avatar" className="h-full w-full" />
          </div>

          <div className="relative">
            <button
              onClick={toggleMenu}
              className="p-3.5 h-10 w-10 rounded-lg border-b flex justify-center items-center bg-transparent"
            >
              <img src={MenuIcon} alt="MenuIcon" className="max-w-none" />
            </button>

            {showMenu && (
              <div className="transition-opacity bg-white absolute min-w-72 py-5 border border-[#F3F3F4] rounded-2xl flex flex-col gap-3 justify-center right-0 top-12 z-50">
                <DisplayNavItem navItemList={NavItemList} toggleMenu={toggleMenu}/>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default NavBar;
